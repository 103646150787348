import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import queryUtils from "utils/queryUtils";

export const licenseFields = `
    id
    name
    organizerName
    organizerNameMl
    isSuperlicense
    studentsLimit
    eventsLimit
    number
    priority
    key
    address
    contacts
    comments
    isIssued
    isActivated
    startsAt
    endsAt
    dataSize
    dataLimit
    eventsNumber
    prefix
    templateDurationMonths
    catalogs {
      id
      name
    }
    regions {
      id
      name
    }
`;


const LicensesQuery = gql`
  query LicensesQuery {
    licenses {
      ${licenseFields}
    }
  }
`;


const AdminsWithUsersQuery = gql`
  query AdminsWithUsersQuery($licenseId: ID!) {
    adminsWithUsers(licenseId: $licenseId) {
      admin {
        id
        email
      }
      user {
        id
        name
        active
        parameters
      }
    }
  }
`;



export const queryLicenses =
    graphql(LicensesQuery, {
        options: {
            fetchPolicy: 'network-only' },
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {licenses: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {licenses: data.error && data.error.message}),
            licenses: data.licenses
        })
    });


export const queryAdminsWithUsers =
    graphql(AdminsWithUsersQuery, {
        options: ({licenseId}) => ({
            variables: {licenseId: licenseId},
            fetchPolicy: 'network-only' }),
        skip: ({licenseId}) => !licenseId,
        props: ({ ownProps, data }) => ({
            loading: queryUtils.mergeWith(ownProps.loading, {adminsWithUsers: data.loading}),
            errors: queryUtils.mergeWith(ownProps.errors, {adminsWithUsers: data.error && data.error.message}),
            adminsWithUsers: data.adminsWithUsers
        })
    });

