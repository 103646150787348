import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import compose from 'lodash/flowRight'

import {breadcrumbsStore} from 'appBase/TopNav'

import useSmartNavigate from 'hooks/useSmartNavigate'

import withUriParams from 'hocs/withUriParams'
import withInitialDataLoadWaiting from 'hocs/withInitialDataLoadWaiting'
import withGeneratorDataSource from 'hocs/withGeneratorDataSource'
import GeneratorBlock from 'components/fieldBlocks/GeneratorBlock'
import SaveButtonsBlock from 'components/blocks/SaveButtonsBlock'
import NamedBlock from 'components/blocks/NamedBlock'
import ListWithSort from 'components/blocks/ListWithSort'
import PanelModal from 'components/modals/PanelModal'
import {SERVICE_DEVICES_VIEW} from 'constants/constants'

import entityUtils from 'utils/entityUtils'

import {queryServiceDevices} from '../data/serviceQueries'
import {createDevice, updateDevice, deleteDevice} from '../data/serviceMutations'
import {createMlJSONStringWithCurrentLanguage} from 'utils/translationUtils'

// TODO: использовать существующий редактор устройств
const DeviceView = compose(
     withUriParams([['deviceId', Number]]),
     queryServiceDevices,
     createDevice, updateDevice, deleteDevice,
     withGeneratorDataSource,
     withInitialDataLoadWaiting(['serviceDevices'])
)((props) => {

    const {deviceId, serviceDevices, createDevice, updateDevice, deleteDevice,
           dataSource, setDataSource, saveAndReset, isChanged, hasErrors, onChange, onValidate} = props;
    const navigate = useSmartNavigate();
    const {t, i18n} = useTranslation();

    //device object for dataSource
    const deviceSource = useMemo(() =>
         deviceId && serviceDevices.find(device => device.id === deviceId),
    [deviceId, serviceDevices]);

    // setting dataSource
    useEffect(() => {
        setDataSource(
            deviceSource,
            (data) => {
                data = entityUtils.filterFields(data, [
                    'id',
                    'nameMl',
                    'descriptionMl',
                    'parameters'
                ]);
                updateDevice(data);
        });
    }, [deviceSource]);

    // preparing links
    breadcrumbsStore.register(SERVICE_DEVICES_VIEW);
    useEffect(() => {
        breadcrumbsStore.set(SERVICE_DEVICES_VIEW, {name: t('appService.devices'), to: `${props.uri}`}, true);
    });

    //rows
    const rows = serviceDevices.map(device => ({
        fields: [
            device.name,
            device.description,
            device.createdAt && device.createdAt.substring(0, 10),
            device.modifiedAt && device.modifiedAt.substring(0, 10)],
        actions: {
            "select": () => navigate(`${props.uri}/${device.id}${props.location.search}`),
            "removeConfirmation": () => deleteDevice(device.id)
         }
    }));

    rows.push({
        fields: [t('appService.views.DeviceView.createNewDevice')],
        className: "text-end",
        notSelectable: true,
        actions: {
            "click": () => createDevice({
                nameMl: createMlJSONStringWithCurrentLanguage(t('appService.views.DeviceView.newDevice'), i18n)
            }).then(({data: {createDevice: { id }}}) => {
                navigate(`${props.uri}/${id}${props.location.search}`);
            })
        }
    });

    //onSave, onCancel
    const onSave = () => {
        saveAndReset();
        navigate(`${props.uri}${props.location.search}`);
    };
    const onCancel = () => {
        setDataSource(null);
        navigate(`${props.uri}${props.location.search}`);
    };

    return (
        <div className="d-flex flex-column h-100 w-100">
            <div className="flex-grow d-flex flex-column scroll-parent">
                <div className="flex-grow d-flex scroll-parent">
                    <div className="flex-grow scroll">
                        <h5 className="text-center mt-3">{t('appService.devices')}</h5>
                        <div className="container">
                            <NamedBlock>
                                <ListWithSort headers={[t('appService.views.DeviceView.name'),
                                                        t('appService.views.DeviceView.description'),
                                                        t('appService.views.DeviceView.createdAt'),
                                                        t('appService.views.DeviceView.modifiedAt')]}
                                              sizes={["145*", "260*", "50*", "50*"]}
                                              rows={rows} />
                            </NamedBlock>

                            {/*TODO: сделать нормлаьный редактор устройств на основе спутников*/}
                            {dataSource &&
                            <PanelModal show title={t('appService.views.DeviceView.editDevice')} noPadding scrollable onClose={onCancel}>
                                <SaveButtonsBlock isChanged={isChanged} hasErrors={hasErrors} onSave={onSave} onCancel={onCancel} />

                                <div className="scroll flex-grow d-flex flex-column workpanel-gray">
                                    <GeneratorBlock data={dataSource} onChange={onChange} onValidate={onValidate} wide
                                        items={[
                                        {
                                            type: "string",
                                            name: t('appService.views.DeviceView.name'),
                                            field: "nameMl",
                                            multilanguage: true
                                        },
                                        {
                                            type: "text",
                                            name: t('appService.views.DeviceView.description'),
                                            field: "descriptionMl",
                                            multilanguage: true
                                        },
                                        {
                                            type: "json",
                                            name: t('appService.views.DeviceView.parameters'),
                                            field: "parameters"
                                        }]}/>
                                </div>
                            </PanelModal> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DeviceView;