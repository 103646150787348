import {graphql} from '@apollo/client/react/hoc';
import gql from 'graphql-tag';

import {licenseFields} from './licenseQueries'

// License

const CreateLicense = gql`
  mutation createLicense($data: CreateLicenseMutationInput!) {
    createLicense(data: $data) {
      id
    }
  }
`;

const UpdateLicense = gql`
  mutation updateLicense($data: UpdateLicenseMutationInput!) {
    updateLicense(data: $data) {
      license {
        ${licenseFields}
      }
      error
    }
  }
`;

const DeleteLicense = gql`
  mutation deleteLicense($id: ID!) {
    deleteLicense(id: $id) {
      id
    }
  }
`;

const ActivateLicense = gql`
  mutation activateLicense($data: ActivateLicenseMutationInput!) {
    activateLicense(data: $data) {
      license {
        ${licenseFields}
      }
      error
    }
  }
`;

// Admin

const CreateAdmin = gql`
  mutation createAdmin($data: CreateAdminMutationInput!) {
    createAdmin(data: $data) {
      admin {
        id
        email
      }
      error
    }
  }
`;

const UpdateAdmin = gql`
  mutation updateAdmin($data: UpdateAdminMutationInput!) {
    updateAdmin(data: $data) {
      admin {
        id
        email
      }
      error
    }
  }
`;

const DeleteAdmin = gql`
  mutation deleteAdmin($id: ID!) {
    deleteAdmin(id: $id) {
      id
    }
  }
`;


const RegisterLicense = gql`
  mutation registerLicense($key: String!) {
    registerLicense(key: $key) {
      error
    }
  }
`;


const AddCatalogToLicense = gql`
  mutation addCatalogToLicense($data: AddCatalogToLicenseMutationInput!) {
    addCatalogToLicense(data: $data) {
      error
    }
  }
`;

const DeleteCatalogFromLicense = gql`
  mutation deleteCatalogFromLicense($data: DeleteCatalogFromLicenseMutationInput!) {
    deleteCatalogFromLicense(data: $data) {
      error
    }
  }
`;

const AddRegionToLicense = gql`
  mutation addRegionToLicense($data: AddRegionToLicenseMutationInput!) {
    addRegionToLicense(data: $data) {
      error
    }
  }
`;

const DeleteRegionFromLicense = gql`
  mutation deleteRegionFromLicense($data: DeleteRegionFromLicenseMutationInput!) {
    deleteRegionFromLicense(data: $data) {
      error
    }
  }
`;


export const createLicense = graphql(CreateLicense, {
    props: ({ mutate }) => ({ createLicense: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});
export const updateLicense = graphql(UpdateLicense, {
    props: ({ mutate }) => ({ updateLicense: (data) => mutate({ variables: { data } }) })
});
export const deleteLicense = graphql(DeleteLicense, {
    props: ({ mutate }) => ({ deleteLicense: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});
export const activateLicense = graphql(ActivateLicense, {
    props: ({ mutate }) => ({ activateLicense: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['UserLicensesQuery']}
});

export const createAdmin = graphql(CreateAdmin, {
    props: ({ mutate }) => ({ createAdmin: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['AdminsWithUsersQuery']}
});
export const updateAdmin = graphql(UpdateAdmin, {
    props: ({ mutate }) => ({ updateAdmin: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['AdminsWithUsersQuery']}
});
export const deleteAdmin = graphql(DeleteAdmin, {
    props: ({ mutate }) => ({ deleteAdmin: (id) => mutate({ variables: { id } }) }),
    options: { refetchQueries: ['AdminsWithUsersQuery']}
});

export const registerLicense = graphql(RegisterLicense, {
    props: ({ mutate }) => ({ registerLicense: (key) => mutate({ variables: { key } }) }),
    options: { refetchQueries: ['UserQuery']}
});

export const addCatalogToLicense = graphql(AddCatalogToLicense, {
    props: ({ mutate }) => ({ addCatalogToLicense: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});
export const deleteCatalogFromLicense = graphql(DeleteCatalogFromLicense, {
    props: ({ mutate }) => ({ deleteCatalogFromLicense: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});

export const addRegionToLicense = graphql(AddRegionToLicense, {
    props: ({ mutate }) => ({ addRegionToLicense: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});
export const deleteRegionFromLicense = graphql(DeleteRegionFromLicense, {
    props: ({ mutate }) => ({ deleteRegionFromLicense: (data) => mutate({ variables: { data } }) }),
    options: { refetchQueries: ['LicensesQuery']}
});